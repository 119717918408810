<template>
    <div class="income">
        <!-- 顶部导航 -->
        <div class="title re_relative re_flex_center re_font_18" :class="{ 'is-pad-top': userStore.isPadTop }">
            <van-icon @click="goBack()" class="icon" name="arrow-left" size="22" />
            <span>我的收益</span>
            <span class="re_font_14 re_absolute re_margin_l_8 explain re_color_light" @click="showExplain = true">说明</span>
        </div>

        <!-- 资产信息 -->
        <div class="assets-info re_sizing re_bg_white">
            <div class="re_flex re_flex_align">
                <div class="asset-item">
                    <div class="re_font_12 re_color_light re_font_family">我的金币</div>
                    <div class="re_font_30 re_color_normal re_margin_top_8">{{ userAssets.userIcons }}</div>
                </div>
                <div class="asset-item">
                    <div class="re_font_12 re_color_light re_font_family">我的溜溜积分</div>
                    <div class="re_font_30 re_color_normal re_margin_top_8" style="position: relative;">
                        <span class="re_margin_r_8">{{ userAssets.lzBalance }}</span>
                        <img @click="goCashOuts()" class="cashOuts" src="../../../assets/task/cashOutsRed.png" alt="">
                    </div>
                </div>
            </div>
            <div class="re_font_12 re_color_light re_margin_top_8">{{ userAssets.exchangeCopy }}</div>
            <div class="re_font_12 re_color_light re_margin_top_8 border-top">每日0点自动兑换成溜溜积分</div>
        </div>

        <div class="tabBox re_bg_white">
            <!-- 明细类型切换 -->
            <div class="detail-tabs re_flex re_font_14 re_color_light">
                <div class="tab-item re_text_c" 
                    :class="{ active: activeTab === 'coins' }"
                    @click="switchTab('coins')">
                    金币明细
                </div>
                <div class="tab-item re_text_cs"
                    :class="{ active: activeTab === 'points' }"
                    @click="switchTab('points')">
                    溜溜积分明细
                </div>
            </div>

            <!-- 明细列表 -->
            <div class="detail-list re_relative">
                <div v-if="detailList.length" class="detail_vant">
                    <van-list
                        v-model:loading="loading"
                        :finished="finished"
                        @load="getList"
                        :offset="10"
                        finished-text=""
                    >
                        <div v-for="(item, index) in detailList" 
                            :key="index"
                            class="detail-item re_flex re_flex_between">
                            <div class="left re_font_14">
                                <div class="re_color_normal">{{ item.title }}</div>
                                <div class="re_color_light re_margin_top_8">{{ item.createTime }}</div>
                            </div>
                            <div :class="['amount', item.money > 0 ? 're_color_red' : '']">
                                {{ item.type == 1 ? '+' : '-' }}{{ item.money }}{{ activeTab === 'coins' ? '金币' : '积分' }}
                            </div>
                        </div>
                        <!-- 底部提示 -->
                        <div class="bottom re_text_c re_font_12 re_color_light re_text_c re_margin_top_8">
                            仅显示最近7天的明细
                        </div>
                    </van-list>
                </div>
                <div class="re_width_100 re_text_c re_font_14 re_color_light text_center" v-if="detailList.length == 0">
                    <img style="width: 100px;" src="https://tp.kaishuihu.com/dsgc/icon/static/newYear/none.png" alt="">
                    <div class="re_font_14 re_color_light re_margin_top_8">暂无记录~</div>
                </div>
            </div>
        </div>
        <div class="re_font_12 re_color_light re_text_c re_margin_top_16">
            累计已赚<span class="re_color_calendar_color"> {{ userAssets.totalLzBalance || 0 }}积分</span>
        </div>

        <!-- 说明弹窗 -->
        <explain :title="'收益规则'" :visible="showExplain" :rules="userAssets.profitRule" @update:visible="showExplain = $event"></explain>
    </div>
</template>

<script>
import Explain from './components/explain.vue'
import { router } from '../../router'
import { getUserAccountRecordList, getMyAccount } from './api'
import { useUserStore } from '../../stores/user'

export default {
    name: 'Income',
    components: {
        Explain
    },
    data() {
        return {
            activeTab: 'coins',
            showExplain: false,
            userAssets: {
                userIcons: 0,
                lzBalance: 0,
                goldenAmount: 0,
                totalLzBalance: 0,
                exchangeCopy: '',
                profitRule: ''
            },
            detailList: [],
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                category: 1
            },
            loading: false,
            finished: false,
            userStore:useUserStore()
        }
    },
    mounted() {
        // 从路由参数获取 tab 类型
        this.activeTab = this.$route.query.type || 'coins'
        this.getList()
        this.getAccount()
    },
    methods: {
        goCashOuts() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                android.goCashOuts();
            }
            if (isiOS) {
                window.webkit.messageHandlers.AppModel.postMessage('goCashOuts');
            }
        },
        async getList() {
            try {
                this.loading = true;
                this.pageParam.category = this.activeTab === 'coins' ? 1 : 2;
                const res = await getUserAccountRecordList(this.pageParam);
                if(res.code === 1) {
                    this.loading = false;
                    this.detailList = this.detailList.concat(res.data);
                    if (res.data.length < this.pageParam.pageSize) {
                        this.finished = true;
                        return;
                    }
                    this.pageParam.pageNum++;
                }
            } catch (error) {
                console.error('获取明细列表失败:', error)
            }
        },
        async getAccount() {
            try {
                const res = await getMyAccount()
                if(res.code === 1) {
                    this.userAssets = res.data
                }
            } catch (error) {
                console.error('获取账户信息失败:', error)
            }
        },
        switchTab(tab) {
            this.activeTab = tab;
            this.pageParam.pageNum = 1;
            this.finished = false;
            this.detailList = [];
            this.getList()
        },
        goBack() {
            router.go(-1)
        }
    }
}
</script>

<style scoped lang="less">
.income {
    min-height: 100vh;
    background: #F5F7F9;

    .title {
        padding: 30px 0 18px 0;
        background: #fff;
        font-weight: 600;
        margin-bottom: 10px;

        .icon {
            position: absolute;
            left: 16px;
            bottom: 18px;
        }

        .explain {
            right: 16px;
            bottom: 18px;
        }
    }
    
    .is-pad-top {
        padding-top: 46px;
    }
}

.assets-info {
    padding: 20px 16px 10px;
    width: 343px;
    min-height: 153px;
    border-radius: 16px;
    margin: 0 auto;
    .border-top{
        border-top: 1px solid #F5F7F9;
        padding-top: 12px;
    }
    .asset-item {
        min-width: 40%;
        div{
            position: relative;
            .cashOuts{
                width: 44px;
                position: absolute;
                top: 9px;
            }
        }
    }
}
.tabBox{
    width: 343px;
    border-radius: 16px;
    margin: 10px auto;
    overflow: hidden;
    height: calc(100vh - 315px);
}
.detail-tabs {
    padding: 16px 16px 0 16px;
    
    .tab-item {
        position: relative;
        padding: 8px 16px;
        color: #666;
        margin-right: 24px;
        width: 50%;
        
        &.active {
            color: #1D1D2C;
            font-weight: 600;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 16px;
                height: 2px;
                background: #FF455E;
                border-radius: 1px;
            }
        }
    }
}

.detail-list {
    margin-top: 16px;
    background: #fff;
    height: calc(100vh - 375px);
    .detail_vant {
        max-height: calc(100vh - 400px);
        overflow: scroll;
    }
    .detail-item {
        padding: 16px;
        border-bottom: 1px solid #F5F7F9;

        &:last-child {
            border-bottom: none;
        }

        .amount {
            font-size: 14px;
            color: #333;

            &.re_color_red {
                color: #FF455E;
            }
        }
    }
    .text_center{
        margin-top: 30%;
    }
    .bottom{
        bottom: 16px;
        width: calc(100% - 32px);
    }
}
</style>
